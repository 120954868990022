import { useEffect, useState } from 'react'
import { RedocStandalone } from 'redoc'
import Login from './Login'
import useToken from './useToken'
// import './App.css'

const redirectSubdomain = process.env.PUBLIC_URL.replace('evercharge.com', 'glance.evercharge.com')

async function verifyUser() {
  console.log('verifyUser')
  let data;

  try {
    data = await fetch(process.env.PUBLIC_URL + "/verify", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
      body: JSON.stringify({ token: "fake-token" }),
      credentials: "same-origin",
    })
  }
  catch (e) {
    console.log(`error: ${e}`)
    window.location.href = `https://glance.evercharge.com/login?next=${redirectSubdomain}`
  }
  console.log(`data: ${JSON.stringify(data, null, 2)}`)
  if (!data.ok) {
    console.log(`redirecting to login: ${data.status}`)
    window.location.href = `https://glance.evercharge.com/login?next=${redirectSubdomain}`
    throw new Error('Failed to verify user')
  }
  return data.json()
}

function App() {
  const { token, setToken } = useToken()
  const [isVerified, setVerified] = useState(false);

  useEffect(() => {
    // If the request is apidocs.glance.evercharge.com redirect to apidoocs.evercharge.com
    if (window.location.hostname === 'apidocs.glance.evercharge.com') {
      window.location.href = `https://apidocs.evercharge.com${window.location.pathname}`
      return
    }

    const fetchData = async () => {
      // get the data from the api
      const data = await verifyUser()
      console.log(`data in effect function: ${JSON.stringify(data, null, 2)}`)
      if (!data.verified) {
        window.location.href = `https://glance.evercharge.com/login?next=${redirectSubdomain}`
      }
      setVerified(data.verified);
      setToken(data);
    }

    if (!isVerified) {
      fetchData().catch(console.error);
    }
  }, [isVerified, token, setToken])

  console.log('isVerified', isVerified)

  if (!isVerified) {
    return <Login setToken={setToken} />
  }

  const isProd = process.env.NODE_ENV === 'production'
  console.log(`token: ${JSON.stringify(token, null, 2)}`)
  return (
    <div>
      <RedocStandalone
        specUrl={isProd ? token['openApiUrl'] : 'openapi-dev.yaml'}
        options={{
          nativeScrollbars: true,
          hideDownloadButton: false,
          theme: {
            colors: {
              primary: {
                main: '#00A598'
              }
            },
          },
        }}
      />
    </div>
  )
}

export default App
